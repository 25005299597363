import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/preview.action';
import { Lesson, Training } from '../../models';

export interface State {
  key: string;
  training: {
    loaded: boolean;
    loading: boolean;
    item: Training;
  };
  lesson: {
    loading: boolean;
    loaded: boolean;
    item: Lesson;
  };
  showSidebar: boolean;
}

export const initialState: State = {
  key: null,
  training: {
    loaded: false,
    loading: false,
    item: null
  },
  lesson: {
    loading: true,
    loaded: false,
    item: null
  },
  showSidebar: true
};

const previewReducer = createReducer(
  initialState,

  on(actions.loadTraining, (state, { key }) => ({
    ...state,
    key,
    training: {
      ...state.training,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadTrainingSuccess, (state, { training }) => ({
    ...state,
    training: {
      loading: false,
      loaded: true,
      item: training
    }
  })),

  on(actions.loadTrainingFail, state => ({
    ...state,
    key: null,
    training: {
      loading: false,
      loaded: true,
      item: null
    }
  })),

  on(actions.loadLesson, state => ({
    ...state,
    lesson: {
      ...state.lesson,
      item: null,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadLessonSuccess, (state, { lesson }) => ({
    ...state,
    lesson: {
      loading: false,
      loaded: true,
      item: lesson
    }
  })),

  on(actions.loadLessonFail, state => ({
    ...state,
    lesson: {
      loading: false,
      loaded: true,
      item: null
    }
  })),

  on(actions.toggleSidebar, state => ({
    ...state,
    showSidebar: !state.showSidebar
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return previewReducer(state, action);
}
