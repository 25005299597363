import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { Lang } from '@shared/models';

export const langGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const lang = localStorage.getItem('lang');
  if (!lang) return true;

  if ((lang === Lang.En || lang === Lang.Es) && state.url !== '/') {
    router.navigate(['']);
    return false;
  }
  if (lang === Lang.Uk && !state.url.includes('/ua')) {
    router.navigate(['ua']);
    return false;
  }
  return true;
};
