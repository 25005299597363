import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/notifications.action';

export interface State {
  manager: {
    activeTasks: number;
    unreadMessages: number;
  };
  student: {
    activeTasks: number;
    activeQuizzes: number;
    unreadMessages: number;
  };
  activeUsers: string[];
}

export const initialState: State = {
  manager: {
    activeTasks: null,
    unreadMessages: null
  },
  student: {
    activeTasks: null,
    activeQuizzes: null,
    unreadMessages: null
  },
  activeUsers: []
};

const notificationsReducer = createReducer(
  initialState,

  on(actions.setActiveUsers, (state: State, { activeUsers }) => ({
    ...state,
    activeUsers
  })),

  on(actions.setManagerNotifications, (state: State, { activeTasks, unreadMessages }) => ({
    ...state,
    manager: {
      ...state.manager,
      activeTasks,
      unreadMessages
    }
  })),

  on(
    actions.setStudentNotifications,
    (state: State, { activeTasks, activeQuizzes, unreadMessages }) => ({
      ...state,
      student: {
        ...state.student,
        activeTasks,
        activeQuizzes,
        unreadMessages
      }
    })
  ),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return notificationsReducer(state, action);
}
