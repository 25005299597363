import { Action, ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromParticipantsReducer from '../../participants/store/reducers/participants.reducer';
import * as fromTrainingsReducer from '../../trainings/trainings/store/reducers/trainings.reducer';
import * as fromTrainingReducer from '../../trainings/training/store/reducers/training.reducer';
import * as fromLessonReducer from '../../trainings/content/store/reducers/lesson.reducer';
import * as fromDashboardReducer from './reducers/dashboard.reducer';
import * as fromGroupsReducer from '../../groups/groups/store/reducers/groups.reducer';
import * as fromGroupReducer from '../../groups/group/store/reducers/group.reducer';
import * as fromSubscriptionReducer from '../../subscription/store/reducers/subscription.reducer';
import * as fromQuizzesReducer from '../../quizzes/store/reducers/quizzes.reducer';
import * as fromTasksReducer from '../../tasks/store/tasks.state';
import * as fromCompletedQuizzesReducer from '../../completed-quizzes/store/completed-quizzes.state';
import * as fromTeamReducer from '../../team/store/reducers/team.reducer';
import * as fromPaymentSystemsReducer from '../../sales/payment-systems/store/reducers/payment-systems.reducer';
import * as fromProductsReducer from '../../sales/products/store/reducers/products.reducer';

export interface State {
  dashboard: fromDashboardReducer.State;
  participants: fromParticipantsReducer.State;
  trainings: fromTrainingsReducer.State;
  training: fromTrainingReducer.State;
  lesson: fromLessonReducer.State;
  groups: fromGroupsReducer.State;
  group: fromGroupReducer.State;
  tasks: fromTasksReducer.State;
  subscription: fromSubscriptionReducer.State;
  quizzes: fromQuizzesReducer.State;
  completedQuizzes: fromCompletedQuizzesReducer.State;
  team: fromTeamReducer.State;
  paymentSystems: fromPaymentSystemsReducer.State;
  products: fromProductsReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  dashboard: fromDashboardReducer.reducer,
  participants: fromParticipantsReducer.reducer,
  trainings: fromTrainingsReducer.reducer,
  training: fromTrainingReducer.reducer,
  lesson: fromLessonReducer.reducer,
  groups: fromGroupsReducer.reducer,
  group: fromGroupReducer.reducer,
  tasks: fromTasksReducer.reducer,
  subscription: fromSubscriptionReducer.reducer,
  quizzes: fromQuizzesReducer.reducer,
  completedQuizzes: fromCompletedQuizzesReducer.reducer,
  team: fromTeamReducer.reducer,
  paymentSystems: fromPaymentSystemsReducer.reducer,
  products: fromProductsReducer.reducer
};

const dashboardReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: State, action: Action): State {
  return dashboardReducer(state, action);
}
