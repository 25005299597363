import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/quiz.action';
import { CompletedQuestionSt, QuestionSt, QuizResultSt, QuizScreen, QuizSt } from '@shared/models';

export interface State {
  quiz: {
    data: QuizSt;
    loading: boolean;
    loaded: boolean;
  };
  initialScreenLoading: boolean;
  resultScreenLoading: boolean;
  screen: QuizScreen;
  attemptId: string;
  activeQuestion: QuestionSt;
  completedQuestions: CompletedQuestionSt[];
  submittingAnswer: boolean;
  result: QuizResultSt;
}

export const initialState: State = {
  quiz: {
    data: null,
    loading: false,
    loaded: false
  },
  initialScreenLoading: false,
  resultScreenLoading: false,
  attemptId: null,
  screen: QuizScreen.info,
  activeQuestion: null,
  completedQuestions: [],
  submittingAnswer: false,
  result: null
};

const quizReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    quiz: {
      ...state.quiz,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadFail, state => ({
    ...state,
    quiz: {
      ...state.quiz,
      loading: false,
      loaded: true
    }
  })),

  on(actions.loadSuccess, (state, { quiz }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      loading: false,
      loaded: true,
      data: quiz
    }
  })),

  on(actions.start, state => ({
    ...state,
    initialScreenLoading: true
  })),

  on(actions.startSuccess, actions.startFail, state => ({
    ...state,
    initialScreenLoading: false
  })),

  on(actions.setScreen, (state, { screen }) => ({
    ...state,
    screen
  })),

  on(actions.setAttemptId, (state, { attemptId }) => ({
    ...state,
    attemptId
  })),

  on(actions.setActiveQuestion, (state, { question }) => ({
    ...state,
    activeQuestion: question
  })),

  on(actions.sendAnswer, state => ({
    ...state,
    submittingAnswer: true
  })),

  on(actions.sendAnswerSuccess, actions.sendAnswerFail, state => ({
    ...state,
    submittingAnswer: false
  })),

  on(actions.saveCompletedQuestion, (state, { question }) => ({
    ...state,
    completedQuestions: [...state.completedQuestions, question]
  })),

  on(actions.setCompletedQuestions, (state, { completedQuestions }) => ({
    ...state,
    completedQuestions: [...completedQuestions]
  })),

  on(actions.result, state => ({
    ...state,
    resultScreenLoading: true
  })),

  on(actions.resultSuccess, (state, { result }) => ({
    ...state,
    resultScreenLoading: false,
    result
  })),

  on(actions.resultFail, state => ({
    ...state,
    resultScreenLoading: false
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return quizReducer(state, action);
}
