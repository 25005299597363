import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/preferences.action';

export interface State {
  showSidebar: boolean;
}

export const initialState: State = {
  showSidebar: true
};

const preferencesReducer = createReducer(
  initialState,

  on(actions.toggleSidebar, state => ({
    ...state,
    showSidebar: !state.showSidebar
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return preferencesReducer(state, action);
}
