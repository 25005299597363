import { ActionReducerMap } from '@ngrx/store';

import * as fromCoreReducer from './../core/store/reducers/core.reducer';
import * as fromDashboardReducer from './../dashboard/dashboard/store/dashboard.state';
import * as fromLearningReducer from '../learning/learning/store/learning.state';
import * as fromPreviewReducer from '../preview/store/reducers/preview.reducer';
import * as fromChatReducer from "../chat/store/reducers/chat.reducer";

export interface AppState {
  core: fromCoreReducer.State;
  dashboard: fromDashboardReducer.State,
  learning: fromLearningReducer.State,
  preview: fromPreviewReducer.State,
  chat: fromChatReducer.State
}

export const reducers: ActionReducerMap<AppState> = {
  core: fromCoreReducer.reducer,
  dashboard: fromDashboardReducer.reducer,
  learning: fromLearningReducer.reducer,
  preview: fromPreviewReducer.reducer,
  chat: fromChatReducer.reducer
};
