import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/trainings.action';
import { TrainingMeta } from "../../models";

export interface State {
  loaded: boolean;
  loading: boolean;
  items: TrainingMeta[];
}

export const initialState: State = {
  loaded: false,
  loading: false,
  items: []
};

const trainingsReducer = createReducer(
  initialState,

  on(actions.load, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { trainings }) => ({
    ...state,
    loading: false,
    loaded: true,
    items: trainings
  })),

  on(actions.loadFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    items: []
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return trainingsReducer(state, action);
}
