import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/filters-data.action';
import { FiltersData } from '../../models';

export interface State {
  loaded: boolean;
  loading: boolean;
  filtersData: FiltersData;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  filtersData: null
};

const filtersDataReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { filtersData }) => ({
    ...state,
    loading: false,
    loaded: true,
    filtersData
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: true,
    filtersData: null
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return filtersDataReducer(state, action);
}
