import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/team.action';
import { TeamMember, TeamMemberMeta } from '../../models';

export interface State {
  list: {
    loaded: boolean;
    loading: boolean;
    data: TeamMemberMeta[];
  };
  member: {
    loaded: boolean;
    loading: boolean;
    data: TeamMember;
  };
  invitationKey: string;
  updateGroupPermissionsLoading: boolean;
}

export const initialState: State = {
  list: {
    loaded: false,
    loading: false,
    data: []
  },
  member: {
    loading: false,
    loaded: false,
    data: null
  },
  invitationKey: null,
  updateGroupPermissionsLoading: false
};

const teamReducer = createReducer(
  initialState,

  on(actions.loadTeam, state => ({
    ...state,
    list: {
      loading: true,
      loaded: false,
      data: []
    }
  })),

  on(actions.loadTeamSuccess, (state, { team }) => ({
    ...state,
    list: {
      loading: false,
      loaded: true,
      data: team
    }
  })),

  on(actions.loadTeamFail, state => ({
    ...state,
    list: {
      loading: false,
      loaded: true,
      data: []
    }
  })),

  on(actions.loadTeamMember, state => ({
    ...state,
    member: {
      loading: true,
      loaded: false,
      data: null
    }
  })),

  on(actions.loadTeamMemberSuccess, (state, { member }) => ({
    ...state,
    member: {
      loading: false,
      loaded: true,
      data: member
    }
  })),

  on(actions.loadTeamMemberFail, state => ({
    ...state,
    member: {
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(actions.updatePermissionsSuccess, (state, { member }) => ({
    ...state,
    member: {
      ...state.member,
      data: member
    }
  })),

  on(actions.updateGroupPermission, state => ({
    ...state,
    updateGroupPermissionsLoading: true
  })),

  on(actions.updateGroupPermissionSuccess, (state, { groupsPermissions }) => ({
    ...state,
    member: {
      ...state.member,
      data: {
        ...state.member.data,
        groupsPermissions: [...groupsPermissions]
      }
    },
    updateGroupPermissionsLoading: false
  })),

  on(actions.updateGroupPermissionFail, state => ({
    ...state,
    updateGroupPermissionsLoading: false
  })),

  on(actions.setInvitationKey, (state, { invitationKey }) => ({
    ...state,
    invitationKey
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return teamReducer(state, action);
}
