import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/training.action';
import * as programActions from '../actions/program.action';
import { ParticipantStatistic, Training } from '../../models';

export interface State {
  loaded: boolean;
  loading: boolean;
  item: Training;
  programsSearchQuery: string;
  participantsSearchQuery: string;
  participantStatistic: ParticipantStatistic;
  toggledModules: string[];
}

export const initialState: State = {
  loaded: false,
  loading: false,
  item: null,
  programsSearchQuery: null,
  participantsSearchQuery: null,
  participantStatistic: null,
  toggledModules: []
};

const trainingReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { training }) => ({
    ...state,
    loading: false,
    loaded: true,
    item: training
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: true,
    item: null
  })),

  on(actions.removeLogo, state => ({
    ...state,
    item: {
      ...state.item,
      logo: null
    }
  })),

  on(
    actions.saveSuccess,
    actions.removeParticipantSuccess,
    actions.updateParticipantAccessSuccess,
    actions.updateParticipantAccessDatesSuccess,
    programActions.assignQuizSuccess,
    programActions.removeQuizSuccess,
    programActions.removeModuleSuccess,
    programActions.editAccessDateSuccess,
    programActions.removeLessonSuccess,
    programActions.dndProgramSuccess,
    programActions.editModuleSuccess,
    programActions.moveProgramSuccess,
    (state, { training }) => ({
      ...state,
      item: training
    })
  ),

  on(programActions.toggleModule, (state, { id }) => {
    const isExist = state.toggledModules.some(m => m === id);
    const toggledModules = isExist
      ? state.toggledModules.filter(m => m !== id)
      : [...state.toggledModules, id];
    return {
      ...state,
      toggledModules
    };
  }),

  on(actions.addRequirement, (state, { requirement }) => ({
    ...state,
    item: {
      ...state.item,
      requirements: [...state.item.requirements, requirement]
    }
  })),

  on(actions.removeRequirement, (state, { requirement }) => ({
    ...state,
    item: {
      ...state.item,
      requirements: state.item.requirements.filter(r => r !== requirement)
    }
  })),

  on(actions.addSkill, (state, { skill }) => ({
    ...state,
    item: {
      ...state.item,
      learnSkills: [...state.item.learnSkills, skill]
    }
  })),

  on(actions.removeSkill, (state, { skill }) => ({
    ...state,
    item: {
      ...state.item,
      learnSkills: state.item.learnSkills.filter(r => r !== skill)
    }
  })),

  on(actions.updateProgramsSearchQuery, (state, { query }) => ({
    ...state,
    programsSearchQuery: query
  })),

  on(actions.updateParticipantsSearchQuery, (state, { query }) => ({
    ...state,
    participantsSearchQuery: query
  })),

  on(
    actions.loadParticipantStatisticSuccess,
    (state, { participantStatistic }) => ({
      ...state,
      participantStatistic
    })
  ),

  on(actions.inviteParticipants, state => ({
    ...state,
    loading: true,
    loaded: false,
    item: {
      ...state.item,
      participants: []
    }
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return trainingReducer(state, action);
}
