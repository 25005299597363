import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';

import * as actions from '../actions/core.action';
import { SeoService } from '@core/services/seo.service';

@Injectable()
export class CoreEffect {
  setLang$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setLang),
        tap(({ lang }) => {
          localStorage.setItem('lang', lang);
          this.translateService.use(lang);
        })
      ),
    { dispatch: false }
  );

  manageCanonicalSeoTag$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setLang),
        tap(() => this.seoService.manageCanonical())
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private seoService: SeoService
  ) {}
}
