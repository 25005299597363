import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/dashboard.action';
import { DashboardInfo, Registrations, TrainingsParticipation } from "../../models";

export interface State {
  dashboardInfo: {
    loaded: boolean;
    loading: boolean;
    data: DashboardInfo;
  },
  registrations: {
    loaded: boolean,
    loading: boolean,
    data: Registrations[]
  },
  trainingsParticipation: {
    loaded: boolean,
    loading: boolean,
    data: TrainingsParticipation
  }
}

export const initialState: State = {
  dashboardInfo: {
    loaded: false,
    loading: false,
    data: null,
  },
  registrations: {
    loaded: false,
    loading: false,
    data: null
  },
  trainingsParticipation: {
    loaded: false,
    loading: false,
    data: null
  }
};

const dashboardReducer = createReducer(
  initialState,

  on(actions.load, (state) => ({
    ...state,
    dashboardInfo: {
      ...state.dashboardInfo,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadSuccess, (state, { dashboard }) => ({
    ...state,
    dashboardInfo: {
      ...state.dashboardInfo,
      loading: false,
      loaded: true,
      data: dashboard
    }
  })),

  on(actions.loadFail, (state) => ({
    ...state,
    dashboardInfo: {
      ...state.dashboardInfo,
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(actions.loadRegistrations, (state) => ({
    ...state,
    registrations: {
      ...state.registrations,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadRegistrationsSuccess, (state, { registrations }) => ({
    ...state,
    registrations: {
      ...state.registrations,
      loading: false,
      loaded: true,
      data: registrations
    }
  })),

  on(actions.loadRegistrationsFail, (state) => ({
    ...state,
    registrations: {
      ...state.registrations,
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(actions.loadTrainingsParticipation, (state) => ({
    ...state,
    trainingsParticipation: {
      ...state.trainingsParticipation,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadTrainingsParticipationSuccess, (state, { trainingParticipation }) => ({
    ...state,
    trainingsParticipation: {
      ...state.trainingsParticipation,
      loading: false,
      loaded: true,
      data: trainingParticipation
    }
  })),

  on(actions.loadTrainingsParticipationFail, (state) => ({
    ...state,
    trainingsParticipation: {
      ...state.trainingsParticipation,
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  })),
);

export function reducer(state = initialState, action: Action): State {
  return dashboardReducer(state, action);
}
