import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/lesson.action';
import { Lesson } from '../../models';
import { Task } from '@shared/models/task.model';

export interface State {
  loaded: boolean;
  loading: boolean;
  item: Lesson;
  task: Task;
  sendingTaskAnswer: boolean;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  item: null,
  task: null,
  sendingTaskAnswer: false
};

const lessonReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false,
    task: null,
    sendingTaskAnswer: false
  })),

  on(actions.loadSuccess, (state, { lesson }) => ({
    ...state,
    loading: false,
    loaded: true,
    item: lesson
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: true,
    item: null
  })),

  on(actions.loadTaskSuccess, (state, { task }) => ({
    ...state,
    task: task
  })),

  on(actions.sendAnswer, state => ({
    ...state,
    sendingTaskAnswer: true
  })),

  on(actions.sendAnswerSuccess, (state, { answerSection }) => ({
    ...state,
    task: {
      ...state.task,
      answerSections: [...state.task.answerSections, answerSection]
    }
  })),

  on(actions.sendAnswerSuccess, actions.sendAnswerFail, state => ({
    ...state,
    sendingTaskAnswer: false
  })),

  on(actions.removeAnswerSuccess, (state, { id }) => {
    const answerSections = state.task.answerSections.filter(s => s.id !== id);
    return {
      ...state,
      task: {
        ...state.task,
        answerSections
      }
    };
  }),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return lessonReducer(state, action);
}
