import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/subscriptions.action';
import { SubscriptionMeta } from '../../models';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: SubscriptionMeta[];
}

export const initialState: State = {
  loaded: false,
  loading: false,
  items: []
};

export const subscriptionsReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { items }) => ({
    ...state,
    loading: false,
    loaded: true,
    items
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: true,
    item: []
  }))
);

export function reducer(state: State, action: Action): State {
  return subscriptionsReducer(state, action);
}
