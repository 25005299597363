import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/quizzes.action';
import { QuizListItemSt } from "@shared/models";

export interface State {
  quizzes: QuizListItemSt[];
  loading: boolean;
  loaded: boolean;
}

export const initialState: State = {
  quizzes: [],
  loading: false,
  loaded: false
};

const quizzesReducer = createReducer(
  initialState,

  on(actions.load, (state) => ({
      ...state,
      loading: true,
      loaded: false
    })
  ),

  on(actions.loadFail, (state) => ({
      ...state,
      loading: false,
      loaded: true
    })
  ),

  on(actions.loadSuccess, (state, { quizzes }) => ({
      ...state,
      loading: false,
      loaded: true,
      quizzes
    })
  ),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return quizzesReducer(state, action);
}
