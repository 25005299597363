import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/total-count.action';

export interface State {
  review: number;
  rejected: number;
  approved: number;
}

export const initialState: State = {
  review: null,
  rejected: null,
  approved: null
};

const totalCountReducer = createReducer(
  initialState,

  on(actions.setTotalCount, (state, { totalCount }) => ({
    ...state,
    review: totalCount.review,
    rejected: totalCount.rejected,
    approved: totalCount.approved
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return totalCountReducer(state, action);
}
