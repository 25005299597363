import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/lesson.action';
import { Lesson } from "../../models";

export interface State {
  loaded: boolean;
  loading: boolean;
  item: Lesson;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  item: null
};

const lessonReducer = createReducer(
  initialState,

  on(actions.load, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { lesson }) => ({
    ...state,
    loading: false,
    loaded: true,
    item: lesson
  })),

  on(actions.loadFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    item: null
  })),

  on(
    actions.addSectionSuccess,
    actions.updateSectionSuccess,
    actions.moveSectionSuccess,
    actions.removeSectionSuccess,
    actions.addHomeWorkSectionSuccess,
    actions.updateHomeWorkSectionSuccess,
    actions.moveHomeWorkSectionSuccess,
    actions.removeHomeWorkSectionSuccess,
    (state, { lesson }) => ({
      ...state,
      item: lesson
    })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return lessonReducer(state, action);
}
