import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/subscription.action';
import { SubscriptionHistoryItem } from "../../models";

export interface State {
  history: {
    loaded: boolean;
    loading: boolean;
    items: SubscriptionHistoryItem[];
  }
}

export const initialState: State = {
  history: {
    loaded: false,
    loading: false,
    items: null
  }
};

const subscriptionReducer = createReducer(
  initialState,

  on(actions.loadHistory, (state) => ({
    ...state,
    history: {
      loading: true,
      loaded: false,
      items: []
    }
  })),

  on(actions.loadHistorySuccess, (state, { history }) => ({
    ...state,
    history: {
      loading: false,
      loaded: true,
      items: history
    }
  })),

  on(actions.loadHistoryFail, (state) => ({
    ...state,
    history: {
      loading: false,
      loaded: true,
      items: []
    }
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  })),
);

export function reducer(state = initialState, action: Action): State {
  return subscriptionReducer(state, action);
}
