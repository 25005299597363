<div class="snack-bar">
  <div
    class="snack-bar__icon-wrapper"
    [class.snack-bar__icon-wrapper--info]="data.type === TYPE.info"
    [class.snack-bar__icon-wrapper--success]="data.type === TYPE.success"
    [class.snack-bar__icon-wrapper--error]="data.type === TYPE.error"
  >
    <app-icon class="icon" [icon]="ICONS[data.type]" />
  </div>
  <div class="snack-bar__message">
    {{ data.message | translate }}
  </div>
  <button class="snack-bar__close-btn" type="button" (click)="onClose()">
    <app-icon class="icon-medium dark" [icon]="'x'" />
  </button>
</div>
