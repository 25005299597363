import { Action, ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromQuizzesReducer from './reducers/quizzes.reducer';
import * as fromSortingReducer from './reducers/sorting.reducer';
import * as fromFiltersDataReducer from './reducers/filters-data.reducer';
import * as fromQuizHistoryReducer from './reducers/quiz-history.reducer';

export interface State {
  quizzes: fromQuizzesReducer.State,
  sorting: fromSortingReducer.State;
  filtersData: fromFiltersDataReducer.State;
  quiz: fromQuizHistoryReducer.State
}

export const reducers: ActionReducerMap<State> = {
  quizzes: fromQuizzesReducer.reducer,
  sorting: fromSortingReducer.reducer,
  filtersData: fromFiltersDataReducer.reducer,
  quiz: fromQuizHistoryReducer.reducer
};

const completedQuizzesReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: State, action: Action): State {
  return completedQuizzesReducer(state, action);
}
