import { Action, ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromUserReducer from './user.reducer';
import * as fromSubscriptionsReducer from './subscriptions.reducer';
import * as fromNotificationReducer from './notifications.reducer';

export interface State {
  user: fromUserReducer.State;
  subscriptions: fromSubscriptionsReducer.State;
  notification: fromNotificationReducer.State;
}

export const reducers: ActionReducerMap<State> = {
  user: fromUserReducer.reducer,
  subscriptions: fromSubscriptionsReducer.reducer,
  notification: fromNotificationReducer.reducer
};

const coreReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: State, action: Action): State {
  return coreReducer(state, action);
}
