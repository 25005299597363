import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/groups.action';
import { GroupMeta } from "../../models";

export interface State {
  loaded: boolean;
  loading: boolean;
  data: GroupMeta[];
}

export const initialState: State = {
  loaded: false,
  loading: false,
  data: null
};

const groupsReducer = createReducer(
  initialState,

  on(actions.load, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { groups }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: groups
  })),

  on(actions.loadFail, (state) => ({
    ...state,
    loading: false,
    loaded: false,
    data: null
  })),

  on(actions.createSuccess, (state, { group }) => ({
    ...state,
    loading: false,
    loaded: false,
    data: [...state.data, group]
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return groupsReducer(state, action);
}
