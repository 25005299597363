import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/dashboard.actions';
import { TrainingMeta } from "../../models";

export interface State {
  assignedItems: {
    loaded: boolean;
    loading: boolean;
    items: TrainingMeta[]
  }
}

export const initialState: State = {
  assignedItems: {
    loaded: false,
    loading: false,
    items: null
  }
};

const dashboardReducer = createReducer(
  initialState,

  on(actions.loadAssignedItems, (state) => ({
    ...state,
    assignedItems: {
      ...state.assignedItems,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadAssignedItemsSuccess, (state, { assignedItems }) => ({
    ...state,
    assignedItems: {
      loading: false,
      loaded: true,
      items: assignedItems
    }
  })),

  on(actions.loadAssignedItemsFail, (state) => ({
    ...state,
    assignedItems: {
      loading: false,
      loaded: true,
      items: null
    }
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return dashboardReducer(state, action);
}
