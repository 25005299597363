import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/payment-systems.actions';
import { PaymentSystem, PaymentSystemMeta } from '@shared/models/payment.model';

export interface State {
  paymentSystems: {
    loaded: boolean;
    loading: boolean;
    data: PaymentSystemMeta[];
  };
  paymentSystem: {
    loaded: boolean;
    loading: boolean;
    data: PaymentSystem;
  };
}

export const initialState: State = {
  paymentSystems: {
    loaded: false,
    loading: false,
    data: []
  },
  paymentSystem: {
    loaded: false,
    loading: false,
    data: null
  }
};

const paymentSystemsReducer = createReducer(
  initialState,

  on(actions.loadAll, state => ({
    ...state,
    paymentSystems: {
      ...state.paymentSystems,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadAllSuccess, (state, { paymentSystems }) => ({
    ...state,
    paymentSystems: {
      ...state.paymentSystems,
      loading: false,
      loaded: true,
      data: paymentSystems
    }
  })),

  on(actions.loadAllFail, state => ({
    ...state,
    payments: {
      loading: false,
      loaded: false,
      data: []
    }
  })),

  on(actions.load, state => ({
    ...state,
    paymentSystem: {
      ...state.paymentSystem,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadSuccess, (state, { paymentSystem }) => ({
    ...state,
    paymentSystem: {
      ...state.paymentSystem,
      loading: false,
      loaded: true,
      data: paymentSystem
    }
  })),

  on(actions.loadFail, state => ({
    ...state,
    paymentSystem: {
      loading: false,
      loaded: false,
      data: null
    }
  }))
);

export function reducer(state = initialState, action: Action): State {
  return paymentSystemsReducer(state, action);
}
