import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/sorting.action';
import { Filters, SortParameter } from '@shared/models';

export interface State {
  query: string;
  sort: SortParameter;
  filters: Filters;
}

export const initialState: State = {
  query: null,
  sort: {
    direction: 'desc',
    sortType: 'completedDate'
  },
  filters: null
};

const sortingReducer = createReducer(
  initialState,

  on(actions.setQuery, (state, { query }) => ({
    ...state,
    query
  })),

  on(actions.setSorting, (state, { sortParameter }) => ({
    ...state,
    sort: sortParameter
  })),

  on(actions.setFilters, (state, { filters }) => ({
    ...state,
    filters
  })),

  on(actions.resetFilters, state => ({
    ...state,
    filters: null
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return sortingReducer(state, action);
}
