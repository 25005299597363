import { Routes } from '@angular/router';

import { langGuard } from '@core/guards/lang.guard';
import { UserGuard } from '@core/guards/user.guard';
import { noAuthGuard } from '@core/guards/no-auth.guard';
import { DashboardGuard } from '@core/guards/dashboard.guard';
import { ParticipantGuard } from '@core/guards/participant.guard';
import { SubscriptionsResolver } from '@core/resolvers/subscriptions.resolver';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    providers: [UserGuard],
    canActivate: [langGuard, UserGuard],
    loadComponent: () =>
      import('./home/pages/home-page/home-page.component').then(c => c.HomePageComponent)
  },
  {
    path: 'ua',
    providers: [UserGuard],
    canActivate: [langGuard, UserGuard],
    loadComponent: () =>
      import('./home/pages/home-page/home-page.component').then(c => c.HomePageComponent)
  },
  {
    path: 'auth',
    canActivate: [noAuthGuard],
    loadChildren: () => import('./auth/auth.routes')
  },
  {
    path: 'dashboard',
    providers: [DashboardGuard, SubscriptionsResolver],
    canActivate: [DashboardGuard],
    resolve: [SubscriptionsResolver],
    loadChildren: () => import('./dashboard/dashboard/dashboard.routes')
  },
  {
    path: 'learning',
    providers: [ParticipantGuard],
    canActivate: [ParticipantGuard],
    loadChildren: () => import('./learning/learning/learning.routes')
  },
  {
    path: 'preview',
    loadChildren: () => import('./preview/preview.routes')
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.routes')
  },
  {
    path: 'pricing',
    loadComponent: () => import('./help/pricing/pricing.component').then(c => c.PricingComponent)
  },
  {
    path: 'privacy-policy',
    loadComponent: () =>
      import('./help/privacy-policy/privacy-policy.component').then(c => c.PrivacyPolicyComponent)
  },
  {
    path: 'public-offer',
    loadComponent: () =>
      import('./help/public-offer/public-offer.component').then(c => c.PublicOfferComponent)
  },
  {
    path: 'not-found',
    loadComponent: () => import('./not-found/not-found.component').then(m => m.NotFoundComponent)
  },
  {
    path: '**',
    redirectTo: 'not-found'
  }
];
