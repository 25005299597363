import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';

import * as actions from '../actions/snack-bar.action';
import { SnackBarComponent } from '@core/components/snack-bar/snack-bar.component';
import { SnackBarType } from '../../models';

@Injectable()
export class SnackBarEffect {
  infoMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.infoMessage),
        tap(({ message }) => {
          this.snackBar.openFromComponent(SnackBarComponent, {
            data: {
              type: SnackBarType.info,
              message
            },
            duration: 2500
          });
        })
      ),
    { dispatch: false }
  );

  successMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.successMessage),
        tap(({ message }) => {
          this.snackBar.openFromComponent(SnackBarComponent, {
            data: {
              type: SnackBarType.success,
              message
            },
            duration: 2500
          });
        })
      ),
    { dispatch: false }
  );

  errorMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.errorMessage),
        tap(({ message }) => {
          const errorMessage = message ?? 'error-occurred-please-try-again-later';
          this.snackBar.openFromComponent(SnackBarComponent, {
            data: {
              type: SnackBarType.error,
              message: errorMessage
            },
            duration: 2500
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar
  ) {}
}
