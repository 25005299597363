import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../auth/auth.service';

export const noAuthGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  if (
    !authService.isAuth() ||
    state.url.includes('/auth/verify') ||
    state.url.includes('auth/sign-up-participant') ||
    state.url.includes('auth/invite-participant')
  ) {
    return true;
  }

  inject(Router).navigate(['']);
  return false;
};
