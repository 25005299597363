import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/quizzes.action';
import { DEFAULT_PAGINATION } from '@shared/constants';
import { CompletedQuizMeta, Pagination } from '@shared/models';

export interface State {
  loaded: boolean;
  loading: boolean;
  items: CompletedQuizMeta[];
  pagination: Pagination;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  items: [],
  pagination: DEFAULT_PAGINATION
};

const quizzesReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { items }) => ({
    ...state,
    loading: false,
    loaded: true,
    items
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: true,
    items: []
  })),

  on(actions.setPagination, (state, { pagination }) => ({
    ...state,
    pagination
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return quizzesReducer(state, action);
}
