import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppState } from '../../store/app.state';
import * as actions from '../store/actions/user.action';
import { UserService } from '../services/user.service';
import { UserRole } from '../models';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class UserGuard {
  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private userService: UserService,
    private authService: AuthService
  ) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isAuth()) return true;

    return this.userService.getUser().pipe(
      map(user => {
        if (user) {
          this.store$.dispatch(actions.loadSuccess({ user }));

          if (user.role === UserRole.manager || user.role === UserRole.teamMember) {
            this.router.navigate(['dashboard']);
          }

          if (user.role === UserRole.participant) {
            this.router.navigate(['learning']);
          }
          return false;
        }
        return true;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}
