import { Action, ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromDashboardReducer from '../../dashboard/store/reducers/dashboard.reducer';
import * as fromPreferencesReducer from './preferences/reducers/preferences.reducer';
import * as fromTrainingsReducer from '../../trainings/store/reducers/trainings.reducer';
import * as fromTrainingReducer from '../../training/store/reducers/training.reducer';
import * as fromLessonReducer from '../../training/store/reducers/lesson.reducer';
import * as fromTrainingQuizReducer from '../../training/store/reducers/quiz.reducer';
import * as fromQuizzesReducer from '../../quizzes/store/reducers/quizzes.reducer';
import * as fromQuizReducer from '../../quizzes/store/reducers/quiz.reducer';

export interface State {
  dashboard: fromDashboardReducer.State,
  preferences: fromPreferencesReducer.State,
  trainings: fromTrainingsReducer.State,
  training: fromTrainingReducer.State,
  lesson: fromLessonReducer.State,
  trainingQuiz: fromTrainingQuizReducer.State,
  quizzes: fromQuizzesReducer.State,
  quiz: fromQuizReducer.State
}

export const reducers: ActionReducerMap<State> = {
  dashboard: fromDashboardReducer.reducer,
  preferences: fromPreferencesReducer.reducer,
  trainings: fromTrainingsReducer.reducer,
  training: fromTrainingReducer.reducer,
  lesson: fromLessonReducer.reducer,
  trainingQuiz: fromTrainingQuizReducer.reducer,
  quizzes: fromQuizzesReducer.reducer,
  quiz: fromQuizReducer.reducer
};

const learningReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: State, action: Action): State {
  return learningReducer(state, action);
}
