import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/quiz-history.action';
import { CompletedQuiz } from '@shared/models';

export interface State {
  loaded: boolean;
  loading: boolean;
  data: CompletedQuiz;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  data: null
};

const quizHistoryReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { data }) => ({
    ...state,
    loading: false,
    loaded: true,
    data
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: true,
    data: null
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return quizHistoryReducer(state, action);
}
