import { Action, ActionReducer, ActionReducerMap, combineReducers } from '@ngrx/store';

import * as fromReviewReducer from "./reducers/review.reducer";
import * as fromRejectedReducer from "./reducers/rejected.reducer";
import * as fromApprovedReducer from "./reducers/approved.reducer";
import * as fromTaskReducer from "./reducers/task.reducer";
import * as fromTotalCountReducer from "./reducers/total-count.reducer";
import * as fromSortingReducer from "./reducers/sorting.reducer";
import * as fromFiltersDataReducer from "./reducers/filters-data.reducer";

export interface State {
  review: fromReviewReducer.State,
  rejected: fromRejectedReducer.State,
  approved: fromApprovedReducer.State,
  task: fromTaskReducer.State,
  totalCount: fromTotalCountReducer.State,
  sorting: fromSortingReducer.State,
  filtersData: fromFiltersDataReducer.State
}

export const reducers: ActionReducerMap<State> = {
  review: fromReviewReducer.reducer,
  rejected: fromRejectedReducer.reducer,
  approved: fromApprovedReducer.reducer,
  task: fromTaskReducer.reducer,
  totalCount: fromTotalCountReducer.reducer,
  sorting: fromSortingReducer.reducer,
  filtersData: fromFiltersDataReducer.reducer
};

const tasksReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: State, action: Action): State {
  return tasksReducer(state, action);
}
