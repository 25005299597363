import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/products.actions';
import { Product, ProductMeta } from '@shared/models/product.model';

export interface State {
  products: {
    loaded: boolean;
    loading: boolean;
    data: ProductMeta[];
  };
  product: {
    loaded: boolean;
    loading: boolean;
    data: Product;
    trainingsLoading: boolean;
  };
}

export const initialState: State = {
  products: {
    loaded: false,
    loading: false,
    data: []
  },
  product: {
    loaded: false,
    loading: false,
    data: null,
    trainingsLoading: false
  }
};

const productsReducer = createReducer(
  initialState,

  on(actions.loadAll, state => ({
    ...state,
    products: {
      ...state.products,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadAllSuccess, (state, { products }) => ({
    ...state,
    products: {
      ...state.products,
      loading: false,
      loaded: true,
      data: products
    }
  })),

  on(actions.loadAllFail, state => ({
    ...state,
    products: {
      ...state.products,
      loading: false,
      loaded: false,
      data: []
    }
  })),

  on(actions.load, state => ({
    ...state,
    product: {
      ...state.product,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadSuccess, (state, { product }) => ({
    ...state,
    product: {
      ...state.product,
      loading: false,
      loaded: true,
      data: product
    }
  })),

  on(actions.loadFail, state => ({
    ...state,
    product: {
      ...state.product,
      loading: false,
      loaded: false,
      data: null
    }
  })),

  on(actions.addTraining, state => ({
    ...state,
    product: {
      ...state.product,
      trainingsLoading: true
    }
  })),

  on(actions.addTrainingSuccess, (state, { product }) => ({
    ...state,
    product: {
      ...state.product,
      data: product,
      trainingsLoading: false
    }
  })),

  on(actions.addTrainingFail, state => ({
    ...state,
    product: {
      ...state.product,
      trainingsLoading: false
    }
  })),

  on(actions.removeTraining, state => ({
    ...state,
    product: {
      ...state.product,
      trainingsLoading: true
    }
  })),

  on(actions.removeTrainingSuccess, (state, { product }) => ({
    ...state,
    product: {
      ...state.product,
      data: product,
      trainingsLoading: false
    }
  })),

  on(actions.removeTrainingFail, state => ({
    ...state,
    product: {
      ...state.product,
      trainingsLoading: false
    }
  }))
);

export function reducer(state = initialState, action: Action): State {
  return productsReducer(state, action);
}
