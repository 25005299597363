import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/training.action';
import { Training } from "../../models";

export interface State {
  loaded: boolean;
  loading: boolean;
  item: Training;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  item: null
};

const trainingReducer = createReducer(
  initialState,

  on(actions.load, (state) => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { training }) => ({
    ...state,
    loading: false,
    loaded: true,
    item: training
  })),

  on(actions.loadFail, (state) => ({
    ...state,
    loading: false,
    loaded: true,
    item: null
  })),

  on(actions.updateProgramAvailable, (state, { training }) => ({
    ...state,
    item: training
  })),

  on(actions.setCompletedPrograms, (state, { programs, progress }) => ({
    ...state,
    item: {
      ...state.item,
      trainingProgram: programs,
      progress
    }
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return trainingReducer(state, action);
}
