import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/chat.action';
import { ParticipantDetails } from "../../../dashboard/participants/models";
import { Group } from '../../models';

export interface State {
  participant: {
    loaded: boolean;
    loading: boolean;
    data: ParticipantDetails;
  },
  group: {
    loaded: boolean;
    loading: boolean;
    data: Group;
  }
}

export const initialState: State = {
  participant: {
    loaded: false,
    loading: false,
    data: null
  },
  group: {
    loaded: false,
    loading: false,
    data: null
  }
};

const chatReducer = createReducer(
  initialState,

  on(actions.loadParticipant, (state) => ({
    ...state,
    participant: {
      ...state.participant,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadParticipantSuccess, (state, { participant }) => ({
    ...state,
    participant: {
      ...state.participant,
      loading: false,
      loaded: true,
      data: participant
    }
  })),

  on(actions.loadParticipantFail, (state) => ({
    ...state,
    participant: {
      ...state.participant,
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(actions.loadGroup, (state) => ({
    ...state,
    group: {
      ...state.group,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadGroupSuccess, (state, { group }) => ({
    ...state,
    group: {
      ...state.group,
      loading: false,
      loaded: true,
      data: group
    }
  })),

  on(actions.loadGroupFail, (state) => ({
    ...state,
    group: {
      ...state.group,
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return chatReducer(state, action);
}
