import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/group.action';
import { Group } from '../../models';

export interface State {
  loaded: boolean;
  loading: boolean;
  data: Group;
  participantsLoading: boolean;
  trainingsLoading: boolean;
}

export const initialState: State = {
  loaded: false,
  loading: false,
  data: null,
  participantsLoading: false,
  trainingsLoading: false
};

const groupReducer = createReducer(
  initialState,

  on(actions.load, state => ({
    ...state,
    loading: true,
    loaded: false
  })),

  on(actions.loadSuccess, (state, { group }) => ({
    ...state,
    loading: false,
    loaded: true,
    data: group
  })),

  on(actions.loadFail, state => ({
    ...state,
    loading: false,
    loaded: false,
    data: null
  })),

  on(
    actions.inviteStudentSuccess,
    actions.removeStudentSuccess,
    actions.patchSuccess,
    actions.patchLogoSuccess,
    actions.removeTrainingSuccess,
    actions.addTrainingsSuccess,
    actions.removeTgBotSuccess,
    actions.removeLogoSuccess,
    (state, { group }) => ({
      ...state,
      data: group
    })
  ),

  on(actions.inviteStudent, actions.removeStudent, state => ({
    ...state,
    participantsLoading: true
  })),

  on(
    actions.inviteStudentSuccess,
    actions.inviteStudentFail,
    actions.removeStudentSuccess,
    actions.removeStudentFail,
    state => ({
      ...state,
      participantsLoading: false
    })
  ),

  on(actions.addTrainings, actions.removeTraining, state => ({
    ...state,
    trainingsLoading: true
  })),

  on(
    actions.addTrainingsSuccess,
    actions.addTrainingsFail,
    actions.removeTrainingSuccess,
    actions.removeTrainingFail,
    state => ({
      ...state,
      trainingsLoading: false
    })
  ),

  on(actions.setInitialState, () => ({
    ...initialState
  }))
);

export function reducer(state = initialState, action: Action): State {
  return groupReducer(state, action);
}
