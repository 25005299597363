import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { UserRole } from '../models';
import { AppState } from '../../store/app.state';
import * as actions from '../store/actions/user.action';

@Injectable()
export class ParticipantGuard {
  constructor(
    private userService: UserService,
    private router: Router,
    private store$: Store<AppState>
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getUser().pipe(
      map(user => {
        if (user && user.role === UserRole.participant) {
          this.store$.dispatch(actions.loadSuccess({ user }));

          const learningRedirectUrl = localStorage.getItem('learningRedirectUrl');
          if (learningRedirectUrl) {
            const url = learningRedirectUrl.split('/').filter(u => u?.length);
            this.router.navigate([...url]);
            localStorage.removeItem('learningRedirectUrl');
          }

          return true;
        }
        this.router.navigate(['']);
        return false;
      }),
      catchError(() => {
        if (state.url.includes('/learning/')) {
          localStorage.setItem('learningRedirectUrl', state.url);
          this.router.navigate(['auth', 'sign-in']);
        } else {
          this.router.navigate(['']);
        }
        return of(false);
      })
    );
  }
}
