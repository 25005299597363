import { Action, createReducer, on } from '@ngrx/store';

import * as actions from '../actions/quizzes.actions';
import * as questionsActions from '../actions/questions.actions';
import * as answersActions from '../actions/answers.actions';
import { Question, QuizAdmin } from '@shared/models';

export interface State {
  quizzes: {
    loaded: boolean;
    loading: boolean;
    data: QuizAdmin[];
  };
  query: string;
  quiz: {
    loaded: boolean;
    loading: boolean;
    data: QuizAdmin;
  };
  question: {
    loaded: boolean;
    loading: boolean;
    data: Question;
  };
}

export const initialState: State = {
  quizzes: {
    loaded: false,
    loading: false,
    data: []
  },
  query: null,
  quiz: {
    loaded: false,
    loading: false,
    data: null
  },
  question: {
    loaded: false,
    loading: false,
    data: null
  }
};

const quizReducer = createReducer(
  initialState,

  on(actions.loadAll, state => ({
    ...state,
    quizzes: {
      ...state.quizzes,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadAllSuccess, (state, { quizzes }) => ({
    ...state,
    quizzes: {
      ...state.quizzes,
      loading: false,
      loaded: true,
      data: quizzes
    }
  })),

  on(actions.loadAllFail, state => ({
    ...state,
    quizzes: {
      loading: false,
      loaded: false,
      data: []
    }
  })),

  on(actions.setQuery, (state, { query }) => ({
    ...state,
    query
  })),

  on(actions.load, state => ({
    ...state,
    quiz: {
      ...state.quiz,
      loading: true,
      loaded: false
    }
  })),

  on(actions.loadSuccess, (state, { quiz }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      loading: false,
      loaded: true,
      data: quiz
    }
  })),

  on(actions.loadFail, state => ({
    ...state,
    quiz: {
      loading: false,
      loaded: false,
      data: null
    }
  })),

  on(questionsActions.loadQuestion, state => ({
    ...state,
    question: {
      ...state.question,
      loading: true,
      loaded: false,
      data: null
    }
  })),

  on(questionsActions.loadQuestionSuccess, (state, { question }) => ({
    ...state,
    question: {
      ...state.question,
      loading: false,
      loaded: true,
      data: question
    }
  })),

  on(questionsActions.loadQuestionFail, state => ({
    ...state,
    question: {
      ...state.question,
      loading: false,
      loaded: true,
      data: null
    }
  })),

  on(questionsActions.addQuestionSuccess, (state, { question }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      data: {
        ...state.quiz.data,
        questions: [...state.quiz.data.questions, question]
      }
    }
  })),

  on(answersActions.addAnswerSuccess, (state, { answer }) => ({
    ...state,
    question: {
      ...state.question,
      data: {
        ...state.question.data,
        answers: [...state.question.data.answers, answer]
      }
    }
  })),

  on(answersActions.updateAnswerSuccess, (state, { answer }) => ({
    ...state,
    question: {
      ...state.question,
      data: {
        ...state.question.data,
        answers: [...state.question.data.answers].map(a => (a.id === answer.id ? answer : a))
      }
    }
  })),

  on(answersActions.updateRightAnswerSuccess, (state, { correctAnswers }) => {
    return {
      ...state,
      question: {
        ...state.question,
        data: {
          ...state.question.data,
          correctAnswers
        }
      }
    };
  }),

  on(answersActions.removeAnswerSuccess, (state, { answerId }) => {
    const answers = state.question.data.answers
      .filter(a => a.id !== answerId)
      .map((a, i) => ({ ...a, order: i }));
    return {
      ...state,
      question: {
        ...state.question,
        data: {
          ...state.question.data,
          answers: [...answers]
        }
      }
    };
  }),

  on(answersActions.updateGapContent, (state, { content }) => {
    return {
      ...state,
      question: {
        ...state.question,
        data: {
          ...state.question.data,
          gapExercise: {
            ...state.question.data.gapExercise,
            content
          }
        }
      }
    };
  }),

  on(answersActions.updateGaps, (state, { gaps }) => {
    return {
      ...state,
      question: {
        ...state.question,
        data: {
          ...state.question.data,
          gapExercise: {
            ...state.question.data.gapExercise,
            gaps
          }
        }
      }
    };
  }),

  on(answersActions.updateGapExerciseSuccess, (state, { question }) => {
    return {
      ...state,
      question: {
        ...state.question,
        data: {
          ...state.question.data,
          ...question
        }
      }
    };
  }),

  on(actions.updateSettingsSuccess, (state, { settings }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      data: {
        ...state.quiz.data,
        settings
      }
    }
  })),

  on(actions.patchSuccess, (state, { quiz }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      data: quiz
    }
  })),

  on(questionsActions.dndQuestionsSuccess, (state, { questions }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      data: {
        ...state.quiz.data,
        questions: [...questions]
      }
    }
  })),

  on(questionsActions.updateQuestionSuccess, (state, { question }) => ({
    ...state,
    question: {
      ...state.question,
      data: {
        ...state.question.data,
        explanation: [...question.explanation],
        content: [...question.content]
      }
    }
  })),

  on(questionsActions.updateQuestionNameSuccess, (state, { question }) => ({
    ...state,
    question: {
      ...state.question,
      data: {
        ...state.question.data,
        name: question.name
      }
    }
  })),

  on(questionsActions.updateQuestionListSuccess, (state, { questions }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      data: {
        ...state.quiz.data,
        questions
      }
    }
  })),

  on(answersActions.dndAnswersSuccess, (state, { answers }) => ({
    ...state,
    question: {
      ...state.question,
      data: {
        ...state.question.data,
        answers
      }
    }
  })),

  on(questionsActions.removeQuestionSuccess, (state, { questions }) => ({
    ...state,
    quiz: {
      ...state.quiz,
      data: {
        ...state.quiz.data,
        questions: [...questions]
      }
    },
    question: {
      ...state.question,
      data: null
    }
  })),

  on(actions.setQuestionInitialState, state => ({
    ...state,
    question: {
      loaded: false,
      loading: false,
      data: null
    }
  })),

  on(actions.setQuizInitialState, state => ({
    ...state,
    quiz: {
      loaded: false,
      loading: false,
      data: null
    }
  })),

  on(actions.setQuizzesInitialState, state => ({
    ...state,
    quizzes: {
      loaded: false,
      loading: false,
      data: null
    }
  }))
);

export function reducer(state = initialState, action: Action): State {
  return quizReducer(state, action);
}
