import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

import { IconComponent } from '@components/icon/icon.component';
import { SnackBarData, SnackBarIcons, SnackBarType } from '../../models';

@Component({
  selector: 'app-snack-bar',
  standalone: true,
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  imports: [TranslateModule, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackBarComponent {
  public TYPE = SnackBarType;
  public ICONS = SnackBarIcons;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData,
    public snackBarRef: MatSnackBarRef<SnackBarComponent>
  ) {}

  public onClose(): void {
    this.snackBarRef.dismiss();
  }
}
